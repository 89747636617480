import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { COMPACT, DEFAULT } from './constants';

import {
  mergeQueryParamsForIframeUrl,
  addUserDataToIframeUrl,
  getQueryParamsFromStorage,
} from '../../../../utils/iFrameSection';
import {
  SiteBuilderModule,
  StyledHeading,
  Paragraph,
} from '../sharedComponents';

import { renderStyledElementStyles } from '../../helpers';
import Auth from '../../../../services/AuthService';
import MaximiseScreenWrapper from '../../../shared/MaximiseScreenWrapper/MaximiseScreenWrapper';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.iframeModule)};

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)};
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)};
`;

const IframeHeadingSection = styled.div`
  ${({ theme, dStyles }) =>
    renderStyledElementStyles(theme, dStyles?.elements?.headingSection)}

  ${({ theme, vStyles }) =>
    renderStyledElementStyles(theme, vStyles?.elements?.headingSection)}
`;

export function IframeModule(props) {
  const iframeRef = useRef(null);
  const {
    options: { data: { proxyIncomingQueryParameters = false } = {} } = {},
    pageConfig: { siteId } = {},
    section: {
      id,
      title,
      titleSize,
      text,
      url = '',
      height,
      heightMobile,
      appendUserDataToUrl,
      showMaximiseScreenOption,
      path = '',
      appendIncomingQueryParametersToUrl,
      vendor,
    } = {},
    location: { search: queryFromSiteUrl },
  } = props;

  const themeConfig = useContext(ThemeContext);

  const { iframeModule } = themeConfig.siteBuilderModules;
  const styleMode = !title && !text ? COMPACT : DEFAULT;
  const dStyles = iframeModule.displayStyles[styleMode];
  const vStyles = iframeModule.visualStyles.DEFAULT;

  useEffect(() => {
    const { loggedIn, email } = Auth.getShortAccountDetails();
    let iframeSrc = url;
    const isGlisserVendor = vendor === 'GLISSER' || vendor === 'GLISSER_LIVE';

    if (appendUserDataToUrl && isGlisserVendor && loggedIn) {
      iframeSrc = addUserDataToIframeUrl(iframeSrc, email);
    } else if (
      (queryFromSiteUrl || proxyIncomingQueryParameters) &&
      appendIncomingQueryParametersToUrl
    ) {
      const iFramePassedQueryParams = proxyIncomingQueryParameters
        ? getQueryParamsFromStorage(siteId)
        : queryFromSiteUrl;

      iframeSrc = mergeQueryParamsForIframeUrl(
        iframeSrc,
        path,
        iFramePassedQueryParams,
      );
    }

    iframeRef.current && iframeRef.current.setAttribute('src', iframeSrc);
  }, [
    proxyIncomingQueryParameters,
    appendUserDataToUrl,
    appendIncomingQueryParametersToUrl,
    path,
    queryFromSiteUrl,
    siteId,
    url,
    vendor,
  ]);

  useEffect(() => {
    const iframeHeight = isMobile ? heightMobile : height;
    iframeRef.current?.setAttribute('height', iframeHeight);
  }, []); // eslint-disable-line

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles }}
    >
      <Wrapper>
        {(title || text) && (
          <IframeHeadingSection vStyles={vStyles} dStyles={dStyles}>
            <div className="container">
              <div className="row middle-xs">
                <div className="col-xs-12">
                  <StyledHeading
                    displayStyles={dStyles}
                    content={title}
                    as={titleSize}
                  />
                  <Paragraph content={text} />
                </div>
              </div>
            </div>
          </IframeHeadingSection>
        )}
        <div className="container">
          <div className="row middle-xs">
            <div className="col-xs-12">
              <MaximiseScreenWrapper enabled={showMaximiseScreenOption}>
                <iframe
                  ref={iframeRef}
                  id={id}
                  title={`iframe-${id}`}
                  width="100%"
                />
              </MaximiseScreenWrapper>
            </div>
          </div>
        </div>
      </Wrapper>
    </SiteBuilderModule>
  );
}

IframeModule.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    titleSize: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
    height: PropTypes.number,
    heightMobile: PropTypes.number,
    appendUserDataToUrl: PropTypes.bool,
    showMaximiseScreenOption: PropTypes.bool,
  }).isRequired,
  options: PropTypes.shape({
    data: PropTypes.shape({
      proxyIncomingQueryParameters: PropTypes.bool,
    }),
  }),
  pageConfig: PropTypes.shape({
    siteId: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  options: state.options,
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(withRouter(IframeModule));
