import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import {
  allowScroll,
  preventScroll,
  isFunction,
  exitFullscreen,
  requestFullscreen,
} from '../../../helpers';
import './MaximiseScreenWrapper.scss';

const TOOLBAR_HEIGHT = 36;

export default class MaximiseScreenWrapper extends Component {
  state = {
    fullSizedWindow: false,
    iframeInitialWidth: null,
    iframeInitialHeight: null,
  };

  componentRef = React.createRef();
  bodyWrapperRef = React.createRef();

  componentDidMount() {
    if (isMobile && this.props.popupLayout) {
      this.enterFullscreen();
      this.scrollModalToTop();
    }

    document.addEventListener('fullscreenchange', this.onFullscreenToggle);
    document.addEventListener('mozfullscreenchange', this.onFullscreenToggle);
    document.addEventListener(
      'webkitfullscreenchange',
      this.onFullscreenToggle,
    );
    document.addEventListener('msfullscreenchange', this.onFullscreenToggle);
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.onFullscreenToggle);
    document.removeEventListener(
      'mozfullscreenchange',
      this.onFullscreenToggle,
    );
    document.removeEventListener(
      'webkitfullscreenchange',
      this.onFullscreenToggle,
    );
    document.removeEventListener('msfullscreenchange', this.onFullscreenToggle);
  }

  scrollModalToTop() {
    const modalElement = document.querySelector('.c-modal');
    if (modalElement) {
      modalElement.scrollTo(0, 0);
    }
  }

  // Support iPhone (Safari)
  setFullSizeIframe() {
    const iframeElement = this.bodyWrapperRef.current.querySelector('iframe');
    if (iframeElement) {
      iframeElement.setAttribute(
        'height',
        (window.innerHeight - TOOLBAR_HEIGHT).toString(),
      );
    }
  }

  setFullscreenIframe() {
    const iframeElement = this.bodyWrapperRef.current
      ? this.bodyWrapperRef.current.querySelector('iframe')
      : null;

    if (iframeElement) {
      if (this.isFullscreen()) {
        this.setState(() => ({
          iframeInitialWidth: iframeElement.getAttribute('width'),
          iframeInitialHeight: iframeElement.getAttribute('height'),
        }));

        const iframeWidth = window.screen.width;
        const iframeHeight = window.screen.height - TOOLBAR_HEIGHT;

        iframeElement.setAttribute('width', iframeWidth.toString());
        iframeElement.setAttribute('height', iframeHeight.toString());
      } else {
        const { iframeInitialWidth, iframeInitialHeight } = this.state;

        iframeElement.setAttribute('width', iframeInitialWidth);
        iframeElement.setAttribute('height', iframeInitialHeight);
      }
    }
  }

  // Support iPhone (Safari)
  enterFullSizeWindow() {
    preventScroll();
    this.setFullSizeIframe();

    this.setState({
      fullSizedWindow: true,
    });
  }

  // Support iPhone (Safari)
  exitFullSizeWindow() {
    allowScroll();

    this.setState({
      fullSizedWindow: false,
    });
  }

  enterFullscreen() {
    const fullscreenSupported = requestFullscreen(this.componentRef.current);

    if (fullscreenSupported === false) {
      this.enterFullSizeWindow();
    }
  }

  exitFullscreen() {
    const fullscreenSupported = exitFullscreen();

    if (fullscreenSupported === false) {
      this.exitFullSizeWindow();
    }
  }

  toggleFullscreenHandler = () => {
    if (this.isFullscreen() || this.state.fullSizedWindow) {
      this.exitFullscreen();
    } else {
      this.enterFullscreen();
    }
  };

  closeActionHandler = () => {
    if (this.isFullscreen()) {
      this.exitFullscreen();
    }

    // Support iPhone (Safari)
    if (this.state.fullSizedWindow) {
      this.exitFullSizeWindow();
    }

    if (isFunction(this.props.onCloseHandler)) {
      this.props.onCloseHandler();
    }
  };

  onFullscreenToggle = () => {
    this.setFullscreenIframe();
  };

  isFullscreen() {
    return (
      document.fullscreenElement || document.webkitCurrentFullScreenElement
    );
  }

  render() {
    const { enabled = false, popupLayout, children } = this.props;

    /**
     * Display children only if maximise option isn't enabled
     */
    if (!enabled) {
      return children;
    }

    const componentClassNames = classNames({
      'c-maximise-screen-wrapper': true,
      'c-maximise-screen-wrapper--popup': popupLayout,
      'c-maximise-screen-wrapper--full-size': this.state.fullSizedWindow,
    });

    return (
      <div ref={this.componentRef} className={componentClassNames}>
        <div className="c-maximise-screen-wrapper__toolbar c-maximise-screen-wrapper__toolbar--top">
          <div className="c-maximise-screen-wrapper__toolbar-right">
            <span
              title="Enter Full Screen"
              className="material-icons c-maximise-screen-wrapper__enter-fullscreen"
              onClick={this.toggleFullscreenHandler}
            >
              fullscreen
            </span>
            <span
              title="Exit Full Screen"
              className="material-icons c-maximise-screen-wrapper__exit-fullscreen"
              onClick={this.toggleFullscreenHandler}
            >
              fullscreen_exit
            </span>
            <span
              title="Close"
              className="material-icons c-maximise-screen-wrapper__close"
              onClick={this.closeActionHandler}
            >
              close
            </span>
          </div>
        </div>
        <div
          ref={this.bodyWrapperRef}
          className="c-maximise-screen-wrapper__body"
        >
          {children}
        </div>
      </div>
    );
  }
}

MaximiseScreenWrapper.propTypes = {
  enabled: PropTypes.bool,
  popupLayout: PropTypes.bool,
  onCloseHandler: PropTypes.func,
};
